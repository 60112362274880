import React, { useEffect } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Layout from '../components/common/Layout'
import Page from '../components/glossary/Page'

export const SingleTermPage = ({ data }) => {
  const title = data.page.frontmatter.title
  const body = data.page.html
  const relevantTerms = data.page.frontmatter.relatedTerms
  const meta = data.page.frontmatter.meta

  return (
    <Layout meta={meta}>
      <main>
        <Page title={title} body={body} relevantTerms={relevantTerms} />
      </main>
    </Layout>
  )
}

export default SingleTermPage

export const pageQuery = graphql`
  ## Query for SingleTermPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleTermPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        relatedTerms
        meta {
          title
          description
          absoluteImageUrl
        }
      }
    }
  }
`
